import React from "react"

import Layout from "../components/layout"
import {graphql, Link} from "gatsby";
import image_404 from "../images/nerpa_logo-404.svg";
import {Col, Row} from "antd";

const NotFoundPage = ({data}) => (
    <Layout>
        <div className="not-found-page">
            <Row>
                <Col sm={24} md={10}>
                    <img src={image_404} style={{width: "50%"}} alt="404" className="center-image"/>

                    <div style={{paddingBottom:"30px"}}/>

                    <div className="not-found-text">ТАКОЙ СТРАНИЦЫ У НАС НЕТ.</div>
                    <div className="not-found-text">МОЖНО ВЕРНУТЬСЯ НА <Link to="/">ГЛАВНУЮ</Link> ИЛИ</div>
                    <div className="not-found-text">СЛОЖИТЬ <Link to="/nerpa">НЕРПУ.</Link></div>
                </Col>
            </Row>
        </div>
    </Layout>
);

export default NotFoundPage;

export const query = graphql`
    query {
        site {
            id
        }
    }
`;